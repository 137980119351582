import React, { useEffect, useState } from 'react';

import { Col, Row } from 'antd';
import cep from 'cep-promise';
import { useSelector } from 'react-redux';

import { fetchAllCities, fetchAllStates } from '../../../services/location';
import { cepMask } from '../../../utils';
import { FormItemInput, FormItemSelect } from '../../ant-wrapper/form-item';
import SkeletonContainer from '../../skeleton-container';

export default function EnderecoForm({ form, dataIndex, viewMode = false }) {
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const { estados: states } = useSelector((state) => state.estados);
  async function fetchCities(value) {
    const citiesData = await fetchAllCities(value);
    setCities(citiesData);
  }

  const updateValues = (ad) => {
    const fields = form.getFieldsValue();
    form.setFieldsValue({
      ...fields,
      [dataIndex]: {
        ...fields?.[dataIndex],
        endereco: {
          cidade: ad.city,
          cep: ad.cep,
          bairro: ad.neighborhood,
          estado: ad.state,
          endereco: ad.street,
          numero: ad.number,
          complemento: ad.complement,
        },
      },
    });
  };

  const handleCEP = (value) => {
    value = value.target.value;
    const cepWithMask = cepMask(value);
    value.length === 8
      && cep(value).then((data) => {
        const ad = { ...data, cep: cepWithMask };
        fetchCities(data.state);
        updateValues(ad);
      });
  };

  const fetchStates = async () => {
    try {
      setLoading(true);
      if (form.getFieldsValue()[dataIndex]) {
        const siglaEstado = form.getFieldsValue()[dataIndex]?.endereco?.estado;
        fetchCities(siglaEstado);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStates();
  }, []);

  const handleChange = (value) => {
    fetchCities(value);
  };

  return (
    <SkeletonContainer loading={loading}>
      <Row gutter={[8, 8]}>
        <Col span={6}>
          <FormItemInput
            name={[dataIndex, 'endereco', 'cep']}
            label="CEP"
            viewMode={viewMode}
            onChangeInput={handleCEP}
            required
          />
        </Col>
        <Col span={6}>
          <FormItemSelect
            name={[dataIndex, 'endereco', 'estado']}
            label="Estado"
            items={states}
            itemsLabel="nome"
            itemsValue="sigla"
            viewMode={viewMode}
            onChangeSelect={handleChange}
          />
        </Col>
        <Col span={6}>
          <FormItemSelect
            name={[dataIndex, 'endereco', 'cidade']}
            label="Cidade"
            items={cities}
            itemsLabel="nome"
            itemsValue="nome"
            viewMode={viewMode}
          />
        </Col>
        <Col span={6}>
          <FormItemInput
            name={[dataIndex, 'endereco', 'bairro']}
            label="Bairro"
            viewMode={viewMode}
          />
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={8}>
          <FormItemInput
            name={[dataIndex, 'endereco', 'endereco']}
            label="Endereco"
            viewMode={viewMode}
          />
        </Col>
        <Col span={4}>
          <FormItemInput
            name={[dataIndex, 'endereco', 'numero']}
            label="Numero"
            viewMode={viewMode}
          />
        </Col>
        <Col span={12}>
          <FormItemInput
            name={[dataIndex, 'endereco', 'complemento']}
            label="Complemento"
            viewMode={viewMode}
          />
        </Col>
      </Row>
    </SkeletonContainer>
  );
}
