import React, { useEffect, useState } from 'react';

import { Alert, Button, Card, Col, Form, Input, Row } from 'antd';

import { getDistricts } from '../../../services/districts';
import { getAgentesSaudes } from '../../../services/districts/agentes';
import EnderecoForm from '../../address/forms';
import { FormItemInput, FormItemSelect } from '../../ant-wrapper/form-item';
import PessoaFisicaForm from '../../pessoa_fisica/forms';
import SkeletonContainer from '../../skeleton-container';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 14 }
};
const cardStyle = { borderColor: 'white' };

export default function PacienteForm({
  onFinish,
  setError,
  error,
  initialValues = {},
  loading = false,
  viewMode = false,
  isEdit = false
}) {
  const [form] = Form.useForm();
  const [distritos, setDistritos] = useState([]);
  const [agentes, setAgentes] = useState([]);

  const onEdit = () => {
    setError();
  };

  const getAllDistricts = async () => {
    const data = await getDistricts('', '', false);
    setDistritos(data);
  };

  const getAllAgentes = async () => {
    try {
      const id = JSON.parse(initialValues?.distrito)?.id;
      if (id) {
        const data = await getAgentesSaudes('', { id });
        setAgentes(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDistrict = async (distrito) => {
    form.resetFields(['agente']);
    form.setFieldsValue({ agente: '' });
    try {
      const id = JSON.parse(distrito)?.id;
      const data = await getAgentesSaudes('', { id });
      setAgentes(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllDistricts();
    getAllAgentes();
  }, []);

  return (
    <>
      {error && (
        <>
          <Alert message={error} type="error" />
          <br />
        </>
      )}
      <Form
        form={form}
        onChange={onEdit}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
        {...formItemLayout}
        initialValues={initialValues}
      >
        <Row>
          <Col span={12}>
            <Card title="Dados básicos" style={{ ...cardStyle }}>
              <PessoaFisicaForm
                useCard
                viewMode={viewMode}
                form={form}
                enableCpfRgCheckbok
                isEdit={isEdit}
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card title="Complemento" style={{ ...cardStyle }}>
              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <FormItemInput
                    name="cartao_sus"
                    label="Cartão do SUS"
                    required
                    viewMode={viewMode}
                  />
                </Col>
                <Col span={12}>
                  <FormItemInput
                    name="ocupacao"
                    label="Ocupação"
                    viewMode={viewMode}
                  />
                </Col>
                <Col span={12}>
                  <FormItemInput
                    name="nome_mae"
                    label="Nome da mãe"
                    viewMode={viewMode}
                  />
                </Col>
                <Col span={12}>
                  <FormItemInput
                    name="nome_pai"
                    label="Nome do pai"
                    viewMode={viewMode}
                  />
                </Col>

                <Col span={12}>
                  <FormItemInput
                    name="responsavel"
                    label="Responsável"
                    viewMode={viewMode}
                  />
                </Col>
                <Col span={12}>
                  <FormItemSelect
                    name="distrito"
                    label="Distrito"
                    items={distritos}
                    itemsLabel="nome"
                    render={(distrito) =>
                      `${distrito.codigo} - ${distrito.nome}`
                    }
                    viewMode={viewMode}
                    required
                    stringfyValue
                    onChangeSelect={handleDistrict}
                  />
                </Col>

                <Col span={12}>
                  <FormItemSelect
                    name="agente"
                    label="Agente de Saúde"
                    items={agentes}
                    itemsLabel="nome"
                    viewMode={agentes.length === 0 || viewMode}
                    stringfyValue
                  />
                </Col>
                <Col span={24}>
                  <Form.Item name="observacao" label="Observação">
                    <Input.TextArea disabled={viewMode} />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Row />
          </Col>
        </Row>

        <Card title="Endereço" style={{ ...cardStyle }}>
          <EnderecoForm
            viewMode={viewMode}
            dataIndex="pessoa_fisica"
            form={form}
          />
        </Card>
        {!viewMode && (
          <Row>
            <Col style={{ marginLeft: 25 }} span={16}>
              <Form.Item>
                <Button loading={loading} type="primary" htmlType="submit">
                  Enviar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </>
  );
}
