import history from '../../routes/history';
import {
  failureNotification,
  successNotification,
  toDigitsOnly
} from '../../utils';
import api from '../api';
import ApiException from '../exception/ApiException';
import { preparePessoaFisica, normalizePessoaFisica } from '../utils';

const registerPaciente = async (values) => {
  try {
    values.pessoa_fisica.endereco.cep = toDigitsOnly(
      values.pessoa_fisica.endereco.cep
    );
    values.distrito = JSON.parse(values.distrito);

    if (values.agente) {
      values.agente_id = JSON.parse(values.agente)?.id;
    }

    await api.post('pacientes', values);
    history.push('/pacientes');
    successNotification('Paciente adicionado com sucesso!');
  } catch (error) {
    throw new ApiException(error.response?.data?.error);
  }
};

const deletePaciente = async (id) => {
  try {
    await api.delete(`pacientes/${id}`);
    successNotification('Paciente deletado com sucesso');
  } catch (error) {
    failureNotification(error.response.data.error);
  }
};

const getPacientes = async (page, filter = '', paginated = '') => {
  const { data: pacientes } = await api.get(
    `pacientes?page=${page}&filter=${filter}&paginated=${paginated}`
  );
  return pacientes;
};

const getPaciente = async (id) => {
  try {
    const { data } = await api.get(`pacientes/${id}`);
    data.distrito = JSON.stringify(data.distrito);
    if (data.agente) {
      data.agente = JSON.stringify(data.agente);
    }
    normalizePessoaFisica(data.pessoa_fisica);
    return data;
  } catch (error) {
    throw new ApiException(error.response?.data?.error);
  }
};

const updatePaciente = async (paciente, values) => {
  try {
    values.id = paciente.id;
    preparePessoaFisica(values, paciente);
    values.distrito = JSON.parse(values.distrito);
    if (values.agente) {
      values.agente_id = JSON.parse(values.agente)?.id;
    }
    await api.put('pacientes', values);
    history.push('/pacientes');
    successNotification('Paciente atualizado com sucesso!');
  } catch (error) {
    console.log(error);
    throw new ApiException(error.response?.data?.error);
  }
};

const getPacientesByCpf = async (cpf) => {
  const { data: pacientes } = await api.get(`pacientes?cpf=${cpf}`);
  return normalizeArrayPacientes(pacientes);
};

const getPacientesByCnh = async (cnh) => {
  const { data: pacientes } = await api.get(`pacientes?cnh=${cnh}`);
  return normalizeArrayPacientes(pacientes);
};

const getPacientesByRg = async (rg) => {
  const { data: pacientes } = await api.get(`pacientes?rg=${rg}`);
  return normalizeArrayPacientes(pacientes);
};

const normalizeArrayPacientes = (pacientes) =>
  pacientes.map((data) => {
    data.distrito = JSON.stringify(data.distrito);
    if (data.agente) {
      data.agente = JSON.stringify(data.agente);
    }
    normalizePessoaFisica(data.pessoa_fisica);
    return data;
  });
export {
  registerPaciente,
  deletePaciente,
  getPacientes,
  getPaciente,
  updatePaciente,
  getPacientesByCpf,
  getPacientesByCnh,
  getPacientesByRg
};
