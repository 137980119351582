import React, { useEffect, useState } from "react";

import { MedicineBoxOutlined, TeamOutlined } from "@ant-design/icons";
import { Button, Spin, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";

import AtendimentosTable from "../../../components/atendimentos/table";
import EditButton from "../../../components/buttons/edit";
import PacienteForm from "../../../components/pacientes/forms";
import { showPacientes } from "../../../components/pacientes/modal";
import SolicitacoesTable from "../../../components/solicitacoes/table";
import { InlineContainer, VerticalContainer } from "../../../components/styled";
import ViewContainer from "../../../components/view_container";
import {
  deleteAtendimento,
  getAtendimentosByPaciente,
} from "../../../services/atendimentos";
import {
  getPaciente,
  getPacientesByCnh,
  getPacientesByCpf,
  getPacientesByRg,
} from "../../../services/pacientes";
import {
  gerarRelatorioAtendimento,
  gerarRelatorioSolicitacao,
} from "../../../services/relatorios";
import {
  getSolicitacoesByPaciente,
  deleteSolicitacao,
} from "../../../services/solicitacoes";
import { Creators as PacienteActions } from "../../../store/ducks/pacientes";
import ListProtocolos from "../../protocolos/list";
import { useContext } from "react";
import { UserContext } from "../../../context";

const { TabPane } = Tabs;

export default function EditPaciente({ computedMatch }) {
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const [paciente, setPaciente] = useState();
  const [solicitacoes, setSolicitacoes] = useState();
  const [atendimentos, setAtendimentos] = useState();
  const [viewMode, setViewMode] = useState(true);
  const user = useContext(UserContext);
  const { params } = computedMatch;
  const { loading, error: erro } = useSelector((state) => state.paciente);
  const dispatch = useDispatch();
  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };

  const onFinish = async (values) => {
    dispatch(PacienteActions.updatePacienteRequest(paciente, values));
  };
  const getSolicitacoes = async () => {
    const solicitacoesData = await getSolicitacoesByPaciente(params.id, page);
    setSolicitacoes(solicitacoesData.data);
    setTotal(solicitacoesData.total);
  };
  const getAtendimentos = async () => {
    const atendimentosData = await getAtendimentosByPaciente(params.id);
    setAtendimentos(atendimentosData);
  };

  useEffect(() => {
    getSolicitacoes();
  }, [page]);

  const handleConfirm = async () => {
    if (erro && erro.includes("CPF")) {
      const data = await getPacientesByCpf(paciente?.pessoa_fisica?.cpf);
      showPacientes(data, "CPF");
    }
    if (erro && erro.includes("CNH")) {
      const data = await getPacientesByCnh(paciente?.pessoa_fisica?.cnh);
      showPacientes(data, "CNH");
    }
    if (erro && erro.includes("RG")) {
      const data = await getPacientesByRg(paciente?.pessoa_fisica?.rg);
      showPacientes(data, "RG");
    }
  };
  useEffect(() => {
    setError(erro);
    // handleConfirm();
  }, [erro]);

  const handlePaciente = async () => {
    const data = await getPaciente(params.id);
    await getSolicitacoes();
    await getAtendimentos();
    setPaciente(data);
  };

  async function confirmSolicitacao(record) {
    await deleteSolicitacao(record.id);
    await getSolicitacoes();
  }
  async function confirmAtendimentos(record) {
    await deleteAtendimento(record.id);
    await getAtendimentos();
  }

  const imprimirRelatorioSolicitacao = async () => {
    await gerarRelatorioSolicitacao({ paciente: JSON.stringify(paciente) });
  };

  const imprimirRelatorioAtendimento = async () => {
    await gerarRelatorioAtendimento({ paciente: JSON.stringify(paciente) });
  };
  useEffect(() => {
    handlePaciente();
    if (setError) setError("");
  }, []);

  return (
    <Tabs defaultActiveKey="1" style={{ width: "100%" }}>
      <TabPane
        tab={
          <span>
            <MedicineBoxOutlined />
            Detalhes
          </span>
        }
        key="1"
      >
        <VerticalContainer>
          <InlineContainer>
            <div />
            {paciente?.permissao_editar ? <EditButton viewMode={viewMode} setViewMode={setViewMode} /> : ""}
          </InlineContainer>
          {paciente ? (
            <ViewContainer item={paciente}>
              <PacienteForm
                initialValues={{ ...paciente }}
                onFinish={onFinish}
                error={error}
                setError={setError}
                loading={loading}
                viewMode={viewMode}
                isEdit
              />
            </ViewContainer>
          ) : (
            <Spin />
          )}
        </VerticalContainer>
      </TabPane>
      <TabPane
        tab={
          <span>
            <TeamOutlined />
            Solicitações
          </span>
        }
        key="2"
      >
        <VerticalContainer>
          <InlineContainer>
            <div />
            <Button onClick={() => imprimirRelatorioSolicitacao()}>
              Imprimir
            </Button>
          </InlineContainer>

          <SolicitacoesTable
            handleTableChange={handleTableChange}
            solicitacoes={solicitacoes}
            total={total}
            confirm={confirmSolicitacao}
          />
        </VerticalContainer>
      </TabPane>
      <TabPane
        tab={
          <span>
            <TeamOutlined />
            Agendamentos
          </span>
        }
        key="3"
      >
        <VerticalContainer>
          <InlineContainer>
            <div />
            <Button onClick={() => imprimirRelatorioAtendimento()}>
              Imprimir
            </Button>
          </InlineContainer>
          <AtendimentosTable
            atendimentos={atendimentos}
            confirm={confirmAtendimentos}
            total={atendimentos?.length || 0}
          />
        </VerticalContainer>
      </TabPane>

      <TabPane
        tab={
          <span>
            <TeamOutlined />
            Protocolos
          </span>
        }
        key="4"
      >
        <VerticalContainer>
          <ListProtocolos paciente={paciente} />
        </VerticalContainer>
      </TabPane>
    </Tabs>
  );
}
