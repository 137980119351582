import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
} from "antd";
import locale from "antd/es/date-picker/locale/pt_BR";
import moment from "moment";
import "moment/locale/pt-br";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SITUACOES } from "../../../models/solicitacoes";
import { getProfissionalClinicas } from "../../../services/clinicas/profissional";
import { updateFilaEspera } from "../../../services/fila_espera";
import { getPacientes } from "../../../services/pacientes";
import { getProcedimentos } from "../../../services/procedimentos";
import { getQuotas, verificarSeCotaDisponivel } from "../../../services/quotas";
import {
  getByCurrentMonthAndPaciente,
  updateSolicitacao,
} from "../../../services/solicitacoes";
import { failureNotification } from "../../../utils";
import { FormItemSelect } from "../../ant-wrapper/form-item";
import DetailPacientBox from "../../pacientes/datail-box";
import ProcedimentosListBox from "../../procedimentos/list-box";
import SkeletonContainer from "../../skeleton-container";
import confirmFaltaQuota from "../modal-confirm";
import ListModelSolicitacoes from "../modal-list";
import { PRIORIDADE_FIELDS } from "../../utils";
import { UserContext } from "../../../context";
import history from "../../../routes/history";

const { Option } = Select;
const { confirm } = Modal;
const { TextArea } = Input;

function showConfirm(solicitacoes, paciente) {
  confirm({
    width: "1200px",
    title: "Atenção! Uma ou mais solicitações foram encontradas.",
    icon: <ExclamationCircleOutlined />,
    content: (
      <ListModelSolicitacoes
        solicitacoes={solicitacoes}
        text={`O paciente ${paciente.pessoa_fisica.nome} tem uma ou mais solicitações nesse mês. Deseja continuar mesmo assim?`}
      />
    ),
    okText: "Continuar",
    cancelText: "Cancelar",
    onOk() {
      console.log("Continuar");
    },
    onCancel() {
      console.log("Cancel");
    },
  });
}
const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};
const cardStyle = { borderColor: "white" };

export default function SolicitacaoForm({
  onFinish,
  setError,
  error,
  initialValues = {},
  loading = false,
  viewMode = false,
  disableButtons = false,
}) {
  const [form] = Form.useForm();
  const { filaEspera } = initialValues;

  const prioridadeInicial = initialValues.prioridade;

  const [pacientes, setPacientes] = useState([]);
  const { profissionais } = useSelector((state) => state.profissional);
  const { unidades } = useSelector((state) => state.unidade);
  const { clinicas } = useSelector((state) => state.clinica);
  const [preparoTexto, setPreparoTexto] = useState("");
  const [profRealizadores, setProfissionaisRealizadores] = useState([]);
  const [procedimentos, setProcedimentos] = useState([]);
  const [procedimentosAdicionados, setProcedimentosAdicionados] = useState([]);
  const [procedimentosEmergencia, setProcedimentosEmergencia] = useState([]);
  const [paciente, setPaciente] = useState();
  const [loadingDados, setloadingDados] = useState(false);
  const [loadingAprovar, setLoadingAprovar] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [isMotivoPreenchido, setMotivoPreenchido] = useState(true);
  const [motivo, setMotivo] = useState();
  const [isObsClinicaPreechida, setObsClinicaPreenchida] = useState(true);
  const [observacao_clinica, setObservacaoClinica] = useState();
  const [selectedNaoRealizado, setSelectedNaoRealizado] = useState(false);
  const user = useContext(UserContext);
  const isCancelable = () => {
    const { situacao } = initialValues;
    return situacao === SITUACOES.APROVADO || situacao === SITUACOES.LANCADO;
  };

  const isNotRealizado = () => initialValues.situacao !== SITUACOES.REALIZADO;
  const isNotNaoRealizado = () =>
    initialValues.situacao !== SITUACOES.NAO_REALIZADO;

  const isAprovable = () =>
    initialValues.situacao !== SITUACOES.APROVADO &&
    isNotRealizado() &&
    isNotNaoRealizado();
  const onEdit = () => {
    setError();
  };

  const handleNaoRealizadoChange = (value) => {
    setSelectedNaoRealizado(value);
  };

  const onSearch = async (result) => {
    const pacientesData = await getPacientes(0, result);
    setPacientes(pacientesData.data);
  };

  const getData = async () => {
    let filter = "";
    if (initialValues?.paciente) {
      const pac = JSON.parse(initialValues?.paciente);
      filter = pac.pessoa_fisica?.nome;
    }

    if (initialValues?.preparo) {
      setPreparoTexto(initialValues?.preparo);
    }

    const pacientesData = await getPacientes(0, filter);
    setPacientes(pacientesData.data);
    setloadingDados(false);
    try {
      if (initialValues.clinica) {
        setProfissionaisRealizadores(
          await getProfissionalClinicas(
            "",
            "",
            JSON.parse(initialValues.clinica),
            false
          )
        );
      }
      setProcedimentos(await getProcedimentos("", "", false));
      if (initialValues.procedimentos?.length > 0) {
        for (let procedimento of initialValues.procedimentos) {
          const mes = moment(initialValues.data).format("MM");
          const ano = moment(initialValues.data).format("YYYY");

          const { data: quotas } = await getQuotas(
            "",
            mes,
            ano,
            "",
            procedimento.id
          );

          var quota = quotas.find((quota) => quota.saldo !== null);

          procedimento.saldo = quota?.saldo;
          procedimento.saldo_emergencial = quota?.saldo_emergencial;
        }

        setProcedimentosAdicionados(initialValues.procedimentos);
        setProcedimentosEmergencia(
          initialValues.procedimentos
            .filter((proc) => proc?.pivot?.emergencial)
            .map((proc) => proc.id)
        );
      }
      if (initialValues?.paciente) {
        setPaciente(JSON.parse(initialValues?.paciente));
      }
    } catch (err) {
      console.log(err);
      setError(err.error);
    } finally {
      setloadingDados(false);
    }
  };

  const onChangeData = async (value) => {
    const procedimentosAux = procedimentosAdicionados;

    for (let procedimento of procedimentosAux) {
      const mes = value.format("MM");
      const ano = value.format("YYYY");
      const { data: quotas } = await getQuotas(
        "",
        mes,
        ano,
        "",
        procedimento.id
      );

      var quota = quotas.find((quota) => quota.saldo !== null);

      procedimento.saldo = quota?.saldo;
      procedimento.saldo_emergencial = quota?.saldo_emergencial;
    }

    setProcedimentosAdicionados([...procedimentosAux]);
  };
  useEffect(() => {
    getData();
  }, []);

  const onChangePaciente = async (value) => {
    const pacienteAux = JSON.parse(value);
    setPaciente(pacienteAux);
  };

  const hasSimilarProcedimento = async (pacienteAux, procedimento) => {
    let solicitacaoPaciente = await getByCurrentMonthAndPaciente(
      pacienteAux.id,
      procedimento.id
    );
    // pegar a solicitacao diferente da atual
    if (initialValues.id) {
      solicitacaoPaciente = solicitacaoPaciente.filter(
        (s) => s.id !== initialValues.id
      );
    }
    if (solicitacaoPaciente.length > 0) {
      showConfirm(solicitacaoPaciente, pacienteAux);
    }
  };

  const onChangeClinica = async (value) => {
    form.resetFields(["profissional_clinica"]);
    const clinica = JSON.parse(value);
    try {
      setProfissionaisRealizadores(
        await getProfissionalClinicas("", "", clinica, false)
      );
    } catch (err) {
      setError(err.error);
    }
  };

  const onChangeProcedimento = async (value) => {
    const dataProcedimento = form.getFieldValue("data");

    if (!dataProcedimento) {
      setError(
        "Preencha a data da solicitação antes de adicionar os procedimentos"
      );
      return;
    }
    if (!paciente) {
      setError("Preencha o paciente antes de adicionar os procedimentos");
      return;
    }
    setError("");
    const procedimento = JSON.parse(value);
    const mes = moment(dataProcedimento).format("MM");
    const ano = moment(dataProcedimento).format("YYYY");
    const { data: quotas } = await getQuotas("", mes, ano, "", procedimento.id);

    var quota = quotas.find((quota) => quota.saldo !== null);

    procedimento.saldo = quota?.saldo || 0;
    procedimento.saldo_emergencial = quota?.saldo_emergencial || 0;

    if (!procedimentosAdicionados.find((p) => p.id === procedimento.id)) {
      if (paciente) {
        await hasSimilarProcedimento(paciente, procedimento);
      }
      setProcedimentosAdicionados([...procedimentosAdicionados, procedimento]);
    }
    let texto = preparoTexto;
    if (preparoTexto?.length === 0) {
      texto = procedimento.preparo;
    } else if (
      procedimento.preparo &&
      preparoTexto?.length > 0 &&
      !preparoTexto?.includes(procedimento.preparo)
    ) {
      texto = preparoTexto + "\n" + procedimento.preparo;
    }
    if (texto) {
      texto = texto.replace(/^(?=\n)$|^\s*|\s*$|\n\n+/gm, "");
      setPreparoTexto(texto);
      form.setFieldsValue({ preparo: texto });
    }
  };

  const onDeleteProcedimento = (value) => {
    const procedimentosAux = procedimentosAdicionados.filter(
      (p) => p.id !== value.id
    );
    setProcedimentosAdicionados(procedimentosAux);
    if (preparoTexto?.length > 0 && value.preparo) {
      let texto = preparoTexto?.replace(value.preparo, "");
      texto = texto.replace(/^(?=\n)$|^\s*|\s*$|\n\n+/gm, "");
      setPreparoTexto(texto);
      form.setFieldsValue({ preparo: texto });
    }
  };
  const onOk = (values) => {
    finish(values);
  };

  const finish = async (values) => {
    try {
      console.info("Salvando fila de espera");
      if (filaEspera) {
        const { id } = await onFinish(values);
        let fila = JSON.parse(filaEspera);
        fila.pacientes.forEach((pac) => {
          if (pac.id === paciente.id) {
            pac.pivot = {
              prioridade: prioridadeInicial,
              aprovado: true,
              solicitacao_id: id,
              paciente_id: pac.id,
            };
          }
        });
        updateFilaEspera({ id: fila.id }, fila);
      } else {
        await onFinish(values);
      }
    } catch (err) {
      console.log(err);
      failureNotification(
        "Fila de espera não atualiza, por favor ajustar manualmente."
      );
    }
  };

  const onChangeSelection = (selectedRowKeys, selectedRows) => {
    setProcedimentosEmergencia(selectedRows.map((proc) => proc.id));
  };

  const onCancel = () => {
    console.log("Cancelando");
  };
  const onSubmit = async (values) => {
    let error = false;

    procedimentosAdicionados.forEach((proc) => {
      if (procedimentosEmergencia.includes(proc.id)) {
        if (!proc?.pivot?.justificativa) {
          error = true;
          setError(
            "Preencha a justificativa para a emergência dos procedimentos"
          );
        }
      }
    });
    if (error) return;
    setLoadingButton(true);
    try {
      values.procedimentos = procedimentosAdicionados.map((proc) => {
        return {
          id: proc.id,
          pivot: { justificativa: proc.pivot?.justificativa },
        };
      });
      if (!values.procedimentos.length) {
        setError("Selecione pelo menos um procedimento");
        return;
      }
      values.procedimentos_emergencia = procedimentosEmergencia;
      const data = await verificarSeCotaDisponivel(
        values.procedimentos,
        values.data,
        procedimentosEmergencia
      );
      if (!data.success) {
        confirmFaltaQuota(
          procedimentosAdicionados.filter(
            (pr) => pr.id === data.procedimento_id
          ),
          values.data,
          () => onOk(values),
          onCancel
        );
      } else {
        finish(values);
      }
    } finally {
      setLoadingButton(false);
    }
  };

  const handleSituacao = async (situacao) => {
    const solicitacaoAtualizada = { ...initialValues };
    if (situacao === SITUACOES.CANCELADO) {
      solicitacaoAtualizada.motivo = motivo;
    }

    if (situacao === SITUACOES.NAO_REALIZADO) {
      solicitacaoAtualizada.observacao_clinica = observacao_clinica;
    }

    solicitacaoAtualizada.procedimentos = procedimentosAdicionados.map(
      (proc) => {
        return {
          id: proc.id,
          pivot: { justificativa: proc.pivot?.justificativa },
        };
      }
    );
    solicitacaoAtualizada.procedimentos_emergencia = procedimentosEmergencia;
    solicitacaoAtualizada.situacao = situacao;

    try {
      setLoadingAprovar(true);
      await updateSolicitacao(initialValues, solicitacaoAtualizada);
    } catch (err) {
      setError(err.error);
    } finally {
      setLoadingAprovar(false);
    }
  };

  return (
    <>
      {error && (
        <>
          <Alert message={error} type="error" />
          <br />
        </>
      )}
      <Form
        form={form}
        onChange={onEdit}
        name="register"
        onFinish={onSubmit}
        scrollToFirstError
        {...formItemLayout}
        initialValues={initialValues}
      >
        <Row>
          <SkeletonContainer loading={loadingDados}>
            <Col span={12}>
              <Card title="Dados básicos" style={{ ...cardStyle }}>
                <FormItemSelect
                  name="unidade"
                  label="Unidade de Saúde"
                  items={unidades}
                  itemsLabel={["pessoa_juridica", "nome"]}
                  stringfyValue
                  viewMode={viewMode}
                  required
                />
                <FormItemSelect
                  name="clinica"
                  label="Clinica"
                  items={clinicas}
                  onChangeSelect={onChangeClinica}
                  itemsLabel={["pessoa_juridica", "nome"]}
                  stringfyValue
                  viewMode={viewMode}
                  required
                />
                <FormItemSelect
                  name="profissional"
                  label="Profissional"
                  items={profissionais}
                  itemsLabel={["pessoa_fisica", "nome"]}
                  stringfyValue
                  viewMode={viewMode}
                  required
                />

                <FormItemSelect
                  name="paciente"
                  label="Paciente"
                  onSearch={onSearch}
                  items={pacientes}
                  itemsLabel={["pessoa_fisica", "nome"]}
                  stringfyValue
                  required
                  onChangeSelect={onChangePaciente}
                  viewMode={viewMode}
                />
                <FormItemSelect
                  viewMode={viewMode}
                  name="prioridade"
                  label="Prioridade"
                  items={PRIORIDADE_FIELDS}
                  required
                />
                <DetailPacientBox paciente={paciente} />

                <SkeletonContainer
                  loading={!profRealizadores.length && viewMode}
                >
                  <FormItemSelect
                    viewMode={profRealizadores.length === 0 || viewMode}
                    name="profissional_clinica"
                    label="Profissional Realizador"
                    items={profRealizadores}
                    itemsLabel="profissional"
                    stringfyValue
                    required
                  />
                </SkeletonContainer>
                <Form.Item name="tipo">
                  <Radio.Group
                    disabled={viewMode}
                    defaultValue="ORDEM_CHEGADA"
                    buttonStyle="solid"
                    style={{ marginTop: 16 }}
                  >
                    <Radio.Button value="ORDEM_CHEGADA">
                      Ordem de chegada
                    </Radio.Button>
                    <Radio.Button value="HORA_MARCADA">
                      Hora marcada
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label="Data"
                  name="data"
                  rules={[
                    {
                      required: true,
                      message: "Data é obrigatório!",
                    },
                  ]}
                >
                  <DatePicker
                    showTime
                    locale={locale}
                    style={{ width: "100%" }}
                    disabled={viewMode}
                    format="DD/MM/YYYY HH:mm"
                    placeholder="Seleciona a data"
                    onChange={onChangeData}
                  />
                </Form.Item>

                <Form.Item label="Observação" name="observacao">
                  <TextArea disabled={viewMode} rows={4} />
                </Form.Item>

                <Form.Item label="Preparo" name="preparo">
                  <TextArea disabled={viewMode} rows={4} />
                </Form.Item>

                <Form.Item
                  required
                  label="Observação da clínica"
                  name="observacao_clinica"
                >
                  <Input.TextArea
                    rows={4}
                    value={observacao_clinica}
                    disabled={isObsClinicaPreechida}
                    onChange={(e) => {
                      if (e.target.value) {
                        setObservacaoClinica(e.target.value);
                      }
                    }}
                  />
                </Form.Item>

                {isNotRealizado() && isNotNaoRealizado() && (
                  <Row gutter={[16, 16]}>
                    {user?.type === "CLINICA" && (
                      <>
                        <Col span={4}>
                          <Button
                            type="primary"
                            onClick={() => handleSituacao(SITUACOES.REALIZADO)}
                          >
                            Realizado
                          </Button>
                        </Col>
                        <Col span={4}>
                          <Button
                            type="primary"
                            onClick={() => {
                              setObsClinicaPreenchida(false);
                              if (observacao_clinica) {
                                handleSituacao(SITUACOES.NAO_REALIZADO);
                              } else {
                                setError("Preencha a Observação da Clínica!");
                              }
                            }}
                            danger
                          >
                            Não realizado
                          </Button>
                        </Col>
                      </>
                    )}
                  </Row>
                )}
                <br />
              </Card>
            </Col>
          </SkeletonContainer>

          <SkeletonContainer loading={loadingDados}>
            <Col span={10}>
              <Card
                title={viewMode ? "Procedimentos" : "Adicionar procedimentos"}
                style={{ ...cardStyle }}
              >
                {!viewMode && (
                  <Select
                    value={null}
                    placeholder="Adicionar procedimentos"
                    style={{ width: "100%", marginBottom: "20px" }}
                    onChange={onChangeProcedimento}
                    disabled={viewMode}
                    showSearch
                  >
                    {procedimentos.length > 0 &&
                      procedimentos.map((cli) => (
                        <Option key={cli.id} value={JSON.stringify(cli)}>
                          {cli.nome}
                        </Option>
                      ))}
                  </Select>
                )}
                <ProcedimentosListBox
                  disabled={viewMode}
                  onDelete={onDeleteProcedimento}
                  procedimentos={procedimentosAdicionados}
                  onChangeSelection={onChangeSelection}
                  viewMode={viewMode}
                />
              </Card>
            </Col>
          </SkeletonContainer>
        </Row>
        <br />
        <br />
        {!disableButtons && (
          <>
            <SkeletonContainer loading={loadingDados}>
              <div style={{ marginLeft: "25px" }}>
                {!viewMode && (
                  <Form.Item>
                    <Button
                      loading={loading || loadingButton}
                      type="primary"
                      htmlType="submit"
                    >
                      Enviar
                    </Button>
                  </Form.Item>
                )}
                {viewMode && (
                  <>
                    {isAprovable() && (
                      <Form.Item>
                        {user?.type !== "CLINICA" ? (
                          <Button
                            loading={loadingAprovar}
                            onClick={() => handleSituacao(SITUACOES.APROVADO)}
                            type="primary"
                          >
                            Aprovar
                          </Button>
                        ) : (
                          ""
                        )}
                      </Form.Item>
                    )}
                    {isCancelable() && (
                      <>
                        {user?.type !== "CLINICA" ? (
                          <Form.Item
                            required
                            label="Motivo do cancelamento"
                            name="motivo"
                          >
                            <Input.TextArea
                              onChange={(e) => {
                                if (e.target.value) {
                                  setMotivoPreenchido(false);
                                  setMotivo(e.target.value);
                                } else setMotivoPreenchido(true);
                              }}
                              required
                            />
                          </Form.Item>
                        ) : (
                          ""
                        )}
                        {user?.type !== "CLINICA" ? (
                          <Form.Item>
                            <Button
                              disabled={isMotivoPreenchido}
                              loading={loadingAprovar}
                              type="primary"
                              onClick={() =>
                                handleSituacao(SITUACOES.CANCELADO)
                              }
                              style={{
                                backgroundColor: "#00008b",
                                color: "white",
                              }}
                            >
                              Cancelar solicitação
                            </Button>
                          </Form.Item>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </SkeletonContainer>
          </>
        )}
      </Form>
    </>
  );
}
